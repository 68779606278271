<template>
  <v-dialog v-model="$store.state.dialog" width="800">
    <v-card flat class="card">
      <v-toolbar outlined flat color="grey lighten-4">
        <v-toolbar-title> {{ $t('rfdh-maamlh-aldhyf') }}</v-toolbar-title>
        <v-spacer />
        <v-btn @click="close()" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form @submit.prevent="submit()" ref="form">
        <v-container>
          <v-card outlined>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <h3 class="mb-2">{{ $t('almlahzh') }}</h3>
                  <v-textarea
                    v-model="item.note"
                    :placeholder="$t('almlahzh')"
                    filled
                    outlined
                    hide-details="auto"
                  />
                </v-col>
                <v-col cols="12">
                  <div class="d-flex justify-end">
                    <v-btn
                      :loading="$global.state.loading"
                      color="primary"
                      class="mx-2"
                      @click="close()"
                    >
                      <v-icon>mdi-close</v-icon>
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                      :loading="$global.state.loading"
                      color="error"
                      @click="submit()"
                    >
                      <v-icon>mdi-delete</v-icon>
                      {{ $t('rfdh') }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      item: {},
    };
  },
  computed: {
    isGuest() {
      return this.$route.path.search("guest") > -1;
    },
  },
  mounted() {
    this.reset();
    console.log(new Date().getDate());
    this.$eventBus.$on("fill-fields", () => {});
  },

  methods: {
    reset() {
      this.item = {
        statusId: null,
        note: "",
      };
    },

    submit() {
      this.$global.state.loading = true;
      this.item.statusId = this.$global.state.status.find(
        (item) => item.key == "rejected"
      ).id;
      this.$http
        .put(`/order/status/${this.$store.state.itemDetails.id}`, this.item)
        .then((res) => {
          this.close();
          this.$service.success(this.$t('tm-rfdh-bnjah'));
          this.$eventBus.$emit(`refresh`);
        })
        .catch((err) => {
          this.$service.faild(err.response.data.message);
        })
        .finally(() => (this.$global.state.loading = false));
    },

    close() {
      this.$store.commit("setDialog");
      this.reset();
      this.resetValidation();
      this.$store.commit("setItemDetails", {});
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    getClients() {
      this.$global.dispatch(`getClients`);
    },

    getGuarantees() {
      this.$global.dispatch(`getGuarantees`);
    },

    getProducts() {
      this.$global.dispatch(`getProducts`);
    },
    allowedDates: (val) =>
      parseInt(val.split("-")[2], 10) >= new Date().getDate(),
  },
};
</script>
